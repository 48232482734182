import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import list from '../assets/data/training_resources.json';

const MediaCard = ({ image, title, description, redirect }) => {
  const classes = useStyles();
  const url = redirect.url === undefined ? `/trainingresourcedetail/${title}` : redirect.url;

  return (
    <> 
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia className={classes.media} image={image} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        {redirect.videos.map((a, i) => (
          <CardActions>
            {(title == 'Welcome Church Preparation' ||
              title == 'Welcome Hong Kong Newcomers' ||
              title == 'Welcome Refugees' ||
              title == 'Welcome International Students' ||
              title == 'Announcements') && (
              <a href={a.url} target="_blank" className={classes.link}>
                <Button size="small" color="primary">
                  Start Exploring
                </Button>
              </a>
            )}
          </CardActions>
        ))}
      </Card>
    </>
  );
};

export default MediaCard;

const useStyles = makeStyles({
  root: {
    width: 400,
    maxWidth: '100%',
    height: 350,

    '& > * + *': {
      textDecoration: 'none',
    },
  },
  media: {
    height: 210,
    width: 400,
    
  },
  link: {
    textDecoration: 'none',
    fontSize: '20px',
  },
});
